import React, { useState, useEffect } from "react";
import { Link } from "gatsby";
import {useStaticQuery, graphql } from 'gatsby'
import get from 'lodash/get'
import { Button, Container, Row, Col, Form } from 'react-bootstrap';
import Header from "../components/Header/header";
import Footer from "../components/Footer/footer";
import Banner from "../components/Banner/banner";

import 'bootstrap/dist/css/bootstrap.min.css';
import '../scss/master.scss';
import AboutSection from '../components/SectionAbout/section-about'
import TilesSectionLeft from '../components/section-tiles-left'
import TilesSectionRight from '../components/section-tiles-right'
import LandingTiles from '../components/landing-tiles'
import DataSection from '../components/DataSection/datasection'
import MarketSection from '../components/SectionMarket/section-market'
import NewsSection from '../components/NewSection/newsSection'
import SectionArea from '../components/SectionArea/section-area'
import PropertiesSection from '../components/SectionProperties/section-properties'
import ContactSection from '../components/SectionContact/section-contact'
import Allstories from '../collections/all-stories'
import FilterNews from '../collections/news-filter'
import InnerBanner from "../components/inner-banner-static"
import InnerDetail from "../components/inner-detail-static"
import Header2 from "../components/Header2/header2";
import LandingIntro from '../components/landing-intro'
import Areaguides from '../components/areaguides-listing'
import Officemap from "../components/office-location/office-location";

import CountUp from 'react-countup';
import SEO from "../components/Seo/seo";

class DefaultTemplate extends React.Component {
  componentDidMount() {
    let scrollPos = 0;
    window.addEventListener('scroll', handleScroll, true);
    function handleScroll(e) {
      let headerElement = document.getElementsByClassName("header")[0];
      if(headerElement) {
      if ((document.body.getBoundingClientRect()).top === 0) {
        headerElement.classList.remove("header-fixed")
        // headerElement.classList.add("fixed-top")
      } else if ((document.body.getBoundingClientRect()).top < 0) {
        headerElement.classList.add("header-fixed")
        //headerElement.classList.remove("fixed-top")
      }
      scrollPos = (document.body.getBoundingClientRect()).top;
    }
  }
    return () => {
      window.removeEventListener('scroll', handleScroll);
    }
  }
  render() {
    const Page = get(this, 'props.data.strapiAreaguides')
    const Modules = get(this, 'props.data.strapiAreaguides.Modules')   
    const GlobalConfig = get(this, 'props.data.strapiGlobalConfig')
    const metadesc = "Looking for a "+Page.Title+" property? Read our guide to "+Page.Title+" and get to know more information about the local area.";
    return (
      <>
  <div className="wrapper">
    {/* header start */}
      <Header />
    {/* header end */}
    <SEO title={Page.Meta_Title} description={metadesc} />

    {/* content start */}
    <div className="content area-guide-detail-banner">

    {Modules.map((Modules, i) => {
          return (
            <>
            {Modules.Banner_Image !=null &&
            <Banner AriticleId= {Page.strapiId} location ={Page.Select_Areaguide_Location} breadcrumb="areaguides" Title={Modules.Banner_Title} Content = {Modules.Banner_Content} CTALabel= {Modules.Banner_CTA_1_Label} CTALabel2= {Modules.Banner_CTA_2_Label} CTAUrl= {Modules.Banner_CTA_1_URL} BannerImg={Modules.Banner_Image} VideoURL={Modules.Embed_Video_URL} search={Modules.Show_Search} review={Modules.Show_Review} pagetile={Page.Title} appId="areaguides" imagetransforms ={Page?.imagetransforms}/>
            }
            {Modules.Intro_Title !=null && Modules.Intro_Content !=null &&
            <AboutSection Title = {Modules.Intro_Title} Content = {Modules.Intro_Content} />
            }
            {Modules.Image_Position == "Left" && Modules.Tile_Image !=null &&
              <TilesSectionLeft Title = {Modules.Tile_Title} TileVideo = {Modules.Tile_Embed_Video_URL} Content={Modules.Tile_Content} TileImg={Modules.Tile_Image} AriticleId= {Page.strapiId} appId="areaguides" imagetransforms ={Page?.imagetransforms}/>
            }
            {Modules.Stats !=null &&
            <DataSection StatTitle = {Modules.Statistics_Title} StatDesc = {Modules.Statistics_Description} data={Modules.Stats} />
            }
            {Modules.Image_Position == "Right" && Modules.Tile_Image !=null &&
              <TilesSectionRight Title = {Modules.Tile_Title} Content={Modules.Tile_Content}
              TileImg={Modules.Tile_Image} TileVideo = {Modules.Tile_Embed_Video_URL} AriticleId= {Page.strapiId} appId="areaguides" imagetransforms ={Page?.imagetransforms}/>
            }

            {Modules.Select_Module == "Featured_Properties" &&
            <>
              {Page.Choose_Office && Page.Choose_Office.map((Office, i) => {
              return<>
              <PropertiesSection area={Page.Title} officeId={Office.Office_CRM_ID}  />
              </>
            })}
            </>
            }
            {Modules.Select_Module == "Areaguide_detail_map" &&
            <section className="full-map-area">
              <Officemap data={Page}  Latitude={Page.Latitude} Longitude={Page.Longitude} />
              </section>
            }

            {Modules.Select_Module == "GetIn_Touch" &&
              <ContactSection getInTouch = {GlobalConfig}  />
            }
            </>
            )

    })}

    </div>
    <Footer area={Page.URL} office={Page.Choose_Office} popularSearch="staticdetails" footerDetails = {GlobalConfig} />
  </div>
</>
  )
}
}
export default DefaultTemplate

export const pageQuery = graphql`
  query AreaGuidesQuery($slug: String!) {
      strapiAreaguides(URL: {eq: $slug}) {
        Meta_Description
        Select_Areaguide_Location
        URL
        strapiId
        Meta_Title
        Title
        Latitude
        Longitude
        Choose_Office {
          Area
          Office_CRM_ID
          Title
          URL
          Select_Office_Location
        }
        Modules {
          Banner_Image {
            internal {
                description
            }
          }
          Embed_Video_URL
          Banner_Title
          Banner_Content
          Banner_CTA_1_Label
          Banner_CTA_1_URL {
            URL
            Label
          }
          Banner_CTA_2_Label
          Show_Search
          Show_Review
          Intro_Title
          Intro_Content
          Image_Position
          Tile_Content
            Tile_Image { 
                internal {
                   description
                }
            }
            Tile_Title
            Tile_Embed_Video_URL
            Statistics_Title
            Statistics_Description
            Stats {
              Prefix
              Caption
              Value
              Value_Type
              Decimal_Value
            }
            Select_Module
        }
        imagetransforms {
            Tile_Image_Transforms
            Banner_Image_Transforms
        }
      }
      strapiGlobalConfig {
          Get_In_Touch_Content
          Get_In_Touch_Embed_Video_Link
          Footer_Office_Address
          Footer_Newsletter_Title
          Instagram_Link
          Linkedin_Link
          Twitter_Link
          Facebook_Link
          Get_In_Touch_Image {
            childImageSharp {
              fluid(quality: 100, maxWidth: 900 ,maxHeight: 600) {
              ...GatsbyImageSharpFluid
              }
            }
          }
          Clients {
            Client
            Client_Image {
              publicURL
            }
            Client_Url
          }
          Footer_Links {
            label
            Menu_item {
              URL
              Secondary_URL
            }
          }
        }
    }
  `